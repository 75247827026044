body {
  color: #333;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

a {
  font-size: 14px;
  text-decoration: none;
}

button {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
